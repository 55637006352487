import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import client from "../client";
import { useQuery } from "@apollo/client";
import { GET_NEXT_PAYMENTS } from "../graphql/querys";
import CountUp from "react-countup";

const RevenuePaymentReport = ({ isDarkModeState, isSolesState }) => {
  const isSoles = isSolesState;
  const handleClick = () => {};
  let dataReport = {};
  const { loading, error, data } = useQuery(GET_NEXT_PAYMENTS, {
    client,
    fetchPolicy: "network-only",
  });

  if (loading) return "";
  if (error) return `${error}`;

  if (data.nextPayments) {
    const reportSoles = data.nextPayments.paymentInSoles;
    const reportDollar = data.nextPayments.paymentInDolares;
    dataReport = isSoles ? reportSoles : reportDollar;
  }

  let moneySymbol = isSoles ? String("S/ ") : String("$  ");

  const hasDollarPayments = Object.values(dataReport).length !== 0;

  return (
    <div
      className={`body-container cursor-pointer flex flex-col justify-center  p-8 ${
        isDarkModeState ? "bg-custom-black" : "bg-white"
      } ${
        isDarkModeState ? "text-white" : "text-black"
      } p-8 shadow-lg hover:shadow-xl w-[550px] h-[319px] rounded-[20px]`}
    >
      <div className="">
        <div className="flex flex-row items-center justify-between">
          <div>
            <h1
              className={`text-lg font-bold ${
                isDarkModeState ? "text-custom-lightblue" : "text-custom-blue"
              } text-center`}
            >
              Mis próximos pagos de intereses
            </h1>
          </div>
          <div>
            <Link to="/calendar">
              <button
                className={` icon-revenue ${
                  isDarkModeState
                    ? "bg-custom-lightblue text-custom-blue"
                    : "bg-custom-blue text-white"
                } flex items-center cursor-pointer ml-2 px-2 py-1 rounded-lg`}
                onClick={handleClick}
              >
                Ver más
                <FontAwesomeIcon
                  className={` text-xs ${
                    isDarkModeState
                      ? "text-custom-blue"
                      : "text-custom-lightblue"
                  } ml-2`}
                  icon={faArrowRight}
                />
              </button>
            </Link>
          </div>
        </div>

        <div
          className={`flex flex-wrap gap-4 mt-4 ${
            !hasDollarPayments ? "overflow-y-hidden" : "overflow-y-auto"
          } row-containers max-h-[198px] justify-center`}
        >
          {hasDollarPayments ? (
            Object.values(dataReport).map((payment, index) => (
              <div
                key={index}
                className={`card ${
                  isDarkModeState ? "bg-custom-black" : "bg-white"
                } border ${
                  isDarkModeState ? "border-custom-lightblue" : "border-black"
                }  rounded-xl w-52 h-44 flex flex-wrap gap-2.5  justify-center items-end pt-2 `}
              >
                <div className="flex w-[160px] h-full items-center justify-center min-w-[160px] ">
                  <div className="flex flex-col items-center justify-center">
                    <div>
                      <h2
                        className={`text-[17px] font-bold ${
                          isDarkModeState ? "text-white" : "text-black"
                        } text-center`}
                      >
                        {payment.planName}
                      </h2>
                    </div>
                    <div>
                      <h3
                        className={`text-2xl ${
                          isDarkModeState ? "text-white" : "text-black"
                        } text-center`}
                      >
                        {moneySymbol}
                        <CountUp
                          start={0}
                          end={Number(payment.rentability ?? 0)}
                          duration={2.75}
                          separator=","
                          decimals={2}
                        />
                      </h3>
                    </div>
                    <div>
                      <h4
                        className={`text-sm ${
                          isDarkModeState ? "text-white" : "text-black"
                        } text-center`}
                      >
                        Fecha de pago
                      </h4>
                    </div>
                    <h5
                      className={`text-lg ${
                        isDarkModeState ? "text-white" : "text-black"
                      } text-center`}
                    >
                      {payment.paymentDate}
                    </h5>
                  </div>
                </div>

                {/* <div className="w-[200px] h-40  rounded-xl bg-slate-400"> </div> */}
              </div>
            ))
          ) : (
            <div style={{ height: "calc(100% - 50px)" }}>
              <p
                className={`text-xl m-20 font-bold ${
                  isDarkModeState ? "text-white" : "text-black "
                } text-center`}
              >
                {isSoles
                  ? "Usted no cuenta con planes en soles."
                  : "Usted no cuenta con planes en dólares."}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RevenuePaymentReport;
